<template>
	<div class="dF fC hide-scrollbar" style="overflow-y: scroll">
		<div class="f1 relative">
			<div class="splash-screen gradient">
				<div class="dF jC fC aC p-3 h-full">
					<div class="dF jC fC aC">
						<img v-if="theApp.media && theApp.media.images && theApp.media.images.intro"
							:src="theApp.media.images.intro" style="width:60vh; height: 60vh;" />
						<img v-else-if="Object.keys(theApp).length == 0"
							src="https://ss3.nyc3.digitaloceanspaces.com/bh-dev/no-instance/marketplace.svg"
							style="width:50vh; height: 50vh;" />
						<p class="mt-4 text-center">
							<span class="text-xl ">{{ getSetupTitle(theApp) }}</span>
						</p>
					</div>
					<div class="py-2">
						<slot />
						<a-button size="large" type="primary" @click="$emit('pressed')">{{ buttonText }}</a-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAppIcon } from 'bh-mod'
export default {
	props: ['buttonText'],
	components: {},
	data() {
		return {


		}
	},
	watch: {

	},
	computed: {
		instance() {
			return this.$store.state.instance
		},
		theApp() {
			return this.$store.state.theApp
		},

	},
	methods: {
		getAppIcon,
		getSetupTitle(theApp) {
			if (Object.keys(theApp).length == 0) {
				return 'Welcome to Workplace'
			} else {
				return theApp.media && theApp.media.text && theApp.media.text.en && theApp.media.text.en.setupTitle || theApp.name
			}
		},
	},
	created() {
	}
}
</script>

<style lang="scss">
.splash-screen {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	display: flex;
	place-content: center;
	place-items: center;
	background: var(--light-bg-gray);

	.ant-card-body {
		height: 100%;
		display: flex;
		place-items: center;
		place-content: center;
	}

	.start-splash-screen-dialog {
		width: 100%;
		max-width: 600px;
		height: 500px;
		box-shadow: 1px 4px 10px rgba(100, 100, 100, 0.3);
		opacity: 0;
		transform: translate3d(0, 30px, 0);
		transition: opacity .3s ease-out, transform .3s ease-out;
		pointer-events: none;

		&.show-dialog {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}

		.ant-card-head {
			display: none;
		}
	}

	&.gradient {

		// background:linear-gradient(0deg, var(--purple), transparent);
		&:after {
			position: absolute;

			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 5;

			opacity: 0.8;
		}

	}

	&.amenity-class-out {
		opacity: 0;
	}
}

.center-search-dialog {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	transform-origin: center bottom;
	transition: max-height .3s ease-out;

}

.amenity-dialog {
	[role="tablist"] {
		display: none;
	}

	// .ant-modal-body{
	//   min-height:300px
	// }
}


.modal-loading-mask {
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 10;
	display: flex;
	place-content: center;
	place-items: center;
	font-size: 40px;
	opacity: 0;
	transition: opacity .3s ease-out;
	pointer-events: none;

	&.modal-loading {
		opacity: 1;
		pointer-events: all;
	}
}
</style>
