<template>
	<transition name="fade">
		<div class="introPage" :class="`style-${color}`">
			<div class="introPage-left" :class="getClassesLeft">

				<svg class="introPage__logo" viewBox="0 0 198.026 54.54">
					<defs>
						<clipPath id="clip-path">
							<path id="Path_86" data-name="Path 86" d="M0,2.385H198.026v-54.54H0Z"
								transform="translate(0 52.155)" fill="#fff" />
						</clipPath>
					</defs>
					<g id="Group_1913" data-name="Group 1913" transform="translate(-14 -14)">
						<g id="Group_9" data-name="Group 9" transform="translate(14 14)">
							<path id="Path_82" data-name="Path 82"
								d="M1.036,0,.979.032l0,0V0L.947.015.922,0V.03l-7.8,4.514V31.784L.954,36.3V45.5L-14.74,36.455v-.2l-.01-.006V9.1l-7.913,4.563v8.989l.052-.029v9.3l-.052-.03v8.989L.954,54.492v.048l.041-.024.042.024L16.6,45.571l.082-.047,7.9-4.552V13.634L1.036.066ZM8.8,22.786l.02-.011v-.006L.975,18.243V9.026l0,0,15.771,9.09v18.38l-.072.041L8.825,41.059l-.02.013Z"
								transform="translate(22.663)" fill="#fff" />
						</g>
						<path id="Path_83" data-name="Path 83" d="M141.856-33.614h5.685v21.62h-5.685Z"
							transform="translate(20.487 67.003)" fill="#fff" />
						<g id="Group_10" data-name="Group 10" transform="translate(162.342 24.439)">
							<path id="Path_84" data-name="Path 84" d="M0,.143v2.5H5.68V-3.13Z"
								transform="translate(0 3.13)" fill="#fff" />
						</g>
						<g id="Group_12" data-name="Group 12" transform="translate(14 14)" clip-path="url(#clip-path)">
							<g id="Group_11" data-name="Group 11" transform="translate(58.819 10.439)">
								<path id="Path_85" data-name="Path 85"
									d="M.448,1.357A6.85,6.85,0,0,1-4.126-.33V.9h-5.68V-26.389l5.68-3.278v10.31A6.658,6.658,0,0,1,.448-21.17c4.611,0,8.4,3.459,8.4,11.529,0,6.465-3.046,11-8.4,11M-.626-15.812A3.979,3.979,0,0,0-4.126-14v8.161A4.534,4.534,0,0,0-.3-3.984c2.181,0,3.378-1.895,3.378-5.521,0-4.457-1.483-6.307-3.708-6.307"
									transform="translate(9.806 29.667)" fill="#fff" />
							</g>
						</g>
						<g id="Group_13" data-name="Group 13" transform="translate(106.072 24.548)">
							<path id="Path_87" data-name="Path 87" d="M0,1.337V-25.956l5.68-3.281V1.337Z"
								transform="translate(0 29.237)" fill="#fff" />
						</g>
						<path id="Path_88" data-name="Path 88" d="M77.992-33.619h5.685V-12H77.992Z"
							transform="translate(17.566 67.003)" fill="#fff" />
						<g id="Group_14" data-name="Group 14" transform="translate(95.559 24.431)">
							<path id="Path_89" data-name="Path 89" d="M0,.143v2.5H5.685v-5.78Z"
								transform="translate(0 3.134)" fill="#fff" />
						</g>
						<g id="Group_19" data-name="Group 19" transform="translate(14 14)" clip-path="url(#clip-path)">
							<g id="Group_15" data-name="Group 15" transform="translate(101.702 10.435)">
								<path id="Path_90" data-name="Path 90"
									d="M.567,1.337V-.024A6.658,6.658,0,0,1-4,1.791c-4.611,0-8.4-3.459-8.4-11.529,0-6.465,3.046-10.994,8.4-10.994a6.84,6.84,0,0,1,4.57,1.668v-6.891l5.686-3.281V1.337Zm0-14.866a4.533,4.533,0,0,0-3.83-1.849c-2.181,0-3.378,1.891-3.378,5.517,0,4.447,1.482,6.3,3.708,6.3a3.983,3.983,0,0,0,3.5-1.813Z"
									transform="translate(12.403 29.237)" fill="#fff" />
							</g>
							<g id="Group_16" data-name="Group 16" transform="translate(125.026 10.435)">
								<path id="Path_91" data-name="Path 91"
									d="M.571,1.337V-10.355c0-3.871-1.319-5.023-3.667-5.023s-3.7,1.151-3.7,4.9V1.337h-5.686V-25.956L-6.8-29.237v10.4a7.066,7.066,0,0,1,5.063-1.895c5.518,0,7.988,3.786,7.988,9.883V1.337Z"
									transform="translate(12.486 29.237)" fill="#fff" />
							</g>
							<g id="Group_17" data-name="Group 17" transform="translate(157.001 19.393)">
								<path id="Path_92" data-name="Path 92"
									d="M.6.945H-5.253l-7.779-21.616h6.279L-3.58-10.665c.535,1.646,1.111,3.7,1.36,4.733C-1.974-7-1.4-8.979-.86-10.665L2.314-20.671H8.407Z"
									transform="translate(13.032 20.671)" fill="#fff" />
							</g>
							<g id="Group_18" data-name="Group 18" transform="translate(179.288 18.763)">
								<path id="Path_93" data-name="Path 93"
									d="M.816.572h-12.93a4.162,4.162,0,0,0,4.447,4.08A4.834,4.834,0,0,0-3.958,3.129L.076,6.507a10.084,10.084,0,0,1-7.744,3.5c-6.215,0-10.173-4.407-10.173-11.244,0-6.628,4.162-11.279,9.638-11.279,5.766,0,9.1,5.105,9.1,11.279A18.011,18.011,0,0,1,.816.572M-8.491-7.4c-2.059,0-3.333,1.686-3.541,3.871H-4.62C-4.783-5.462-5.934-7.4-8.491-7.4"
									transform="translate(17.84 12.516)" fill="#fff" />
							</g>
						</g>
					</g>
				</svg>

				<div class="media">
					<img :src="introImage" v-if="!customSteps" />
					<h1 v-else-if="hasSteps" :key="currentStep">
						{{ step.title }}
					</h1>
				</div>
				<div>
					<a-button @click="goBack" v-if="!customSteps || (customSteps && step.backButton)" size="large"
						type='link' class="back-button"><a-icon type='arrow-left' />BACK</a-button>
				</div>
			</div>
			<div class="scroll-parent">
				<div class="introPage-right scroll-child">
					<div v-if="!customSteps"></div>
					<div class="w-full">
						<template v-if="!customSteps">
							<h1>
								{{ title }}
							</h1>
							<p v-html="text">

							</p>
						</template>
						<slot name="right" v-else>

						</slot>
					</div>
					<div>
						<template v-if="!customSteps">
							<a-button v-if="!customSteps || step.showSkip" size="large" class="text-black mx-4"
								type='link' @click="skipIntro">SKIP</a-button>
							<a-button size="large" type='primary' @click="showVid">I'M NEW HERE</a-button>
						</template>
						<template v-else>
							<portal-target name="destination">
							</portal-target>
						</template>
					</div>

				</div>
			</div>
		</div>
	</transition>
</template>

<script>

export default {
	props: {
		'color': {
			type: String,
			default: 'purple',
		},
		'introImage': String,
		title: String,
		text: String,
		step: Object,
		value: Number,
		skipIntroScreen: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			customSteps: false,
			introPageMode: true,
			currentStep: 0,
		}
	},
	watch: {
		value(val) {
			this.currentStep = val
		},
		currentStep(val) {
			return this.$emit('input', val)
		},
		show(val) {
			document.querySelector('.top-bar--fixed').style.zIndex = val ? '-1' : '100'
		}
	},
	computed: {
		getClassesLeft() {
			let classes = ''
			if (this.customSteps && this.step.title) classes += 'intro-left-bg '
			return classes
		},
		hasSteps() {
			if (typeof this.step !== 'object') return false
			return Object.keys(this.step).length !== 0
		},
		introPage() {
			return this.$store.state.siteplan.introPage
		}
	},
	methods: {
		goBack() {
			if (!this.customSteps) {
				this.$emit('close')
			} else {
				if (this.currentStep === 0) this.$emit('close')
				else this.currentStep--
			}
		},
		showVid() {
			console.log('SHOWING VIDEO')
		},
		skipIntro() {
			if (this.hasSteps) {
				this.customSteps = true
				this.currentStep = 0
			} else {
				this.$emit('done')
			}
		},
		nextStep() {

		},
		skip() {

		}
	},
	created() {
		if (this.skipIntroScreen) {
			this.skipIntro();
		}
		let topbar = document.getElementsByClassName('top-bar--fixed')
		for (var i = 0; i < topbar.length; i++) {
			topbar[i].style.zIndex = '-1'
		}
		// document.querySelector('.top-bar--fixed').style.zIndex = '-1'
		// document.querySelector('.footer--app').style.zIndex = '-1'
	},
	beforeDestroy() {
		let topbar = document.getElementsByClassName('top-bar--fixed')
		for (var i = 0; i < topbar.length; i++) {
			topbar[i].style.zIndex = '100'
		}
		// document.querySelector('.top-bar--fixed').style.zIndex = '100'
		// document.querySelector('.footer--app').style.zIndex = '5'
	}
}
</script>

<style lang="scss">
.introPage__logo {
	width: 150px;
	margin-bottom: 10px;

	@media screen and (min-width:576px) {
		width: 200px;
		margin-bottom: 0px;
	}
}

.introPage {
	background: white;
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 100;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 2fr;

	@media screen and (min-width:576px) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
		grid-template-rows: 1fr;
	}

	img {
		max-width: 100%;
	}

	&.style-pink {

		.introPage-left,
		.ant-btn-primary {
			background-color: #FF647C;
			border-color: #FF647C;
		}
	}

	&.style-yellow {

		.introPage-left,
		.ant-btn-primary {
			background-color: #FFCF5C;
			border-color: #FFCF5C;
		}
	}

	&.style-purple {

		.introPage-left,
		.ant-btn-primary {
			background-color: #9693E7;
			border-color: #9693E7;
		}
	}

	&.style-orange {

		.introPage-left,
		.ant-btn-primary {
			background-color: #F7941E;
			border-color: #F7941E;
		}
	}

	&.style-green {

		.introPage-left,
		.ant-btn-primary {
			background-color: #00C48C;
			border-color: #00C48C;
		}
	}

	&.style-blue {

		.introPage-left,
		.ant-btn-primary {
			background-color: #2C94C8;
			border-color: #2C94C8;
		}
	}

	&.style-gray {

		.introPage-left,
		.ant-btn-primary {
			background-color: #40454C;
			border-color: #40454C;
		}
	}


}

.introPage-left,
.introPage-right {

	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

.introPage-right {
	align-items: flex-end;
	overflow-y: scroll;
}

.introPage-left {
	align-items: center;

	@media screen and (min-width:576px) {
		align-items: flex-start;
	}

	&.intro-left-bg {
		position: relative;

		&:before {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-image: url(https://bildhive.nyc3.digitaloceanspaces.com/apps/popupbg@2x.png);
			background-position: 100% 110%;
			background-size: 40vw;
			background-repeat: no-repeat;
			transform-origin: bottom right;
			animation: opacity-intro-in .5s ease-out;
			content: '';
		}

		h1 {
			font-size: 70px;
			color: white;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.media {
		animation: scale-in .8s ease-out;
		animation-fill-mode: forwards;
	}

	.back-button {
		color: white;

		span {
			color: inherit !important;
		}
	}

}

.headline {
	height: 100%;
	background-color: var(--white);
	top: 0px;
}

@keyframes scale-in {
	from {
		transform: scale(.8);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes opacity-intro-in {
	from {
		transform: scale(.8);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

.scroll-parent {
	position: relative;
	overflow: hidden;

	>.scroll-child {
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		right: -17px;
		overflow-y: scroll;
	}
}

.ant-steps-item-description {
	font-size: 12px;
}
</style>
